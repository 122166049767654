import { css } from '@emotion/react'
import { Modal } from '@mantine/core'
import { proxy, ref, useSnapshot } from 'valtio'
import { component } from '~/utils/component'

export class FuiModals<Name extends string> {
  store

  constructor(names: Name[]) {
    this.store = proxy({
      opened: '' as Name | '',
      names,
    })
  }

  open(name: Name) {
    this.store.opened = name
  }

  close() {
    this.store.opened = ''
  }

  WithModalContent = ref(
    component<
      ReactProps<{
        for: Name
        cssset?: CSS
      }>
    >(
      props => {
        const state = useSnapshot(this.store)

        return (
          <Modal
            withCloseButton={false}
            opened={state.opened === props.for}
            size={'auto'}
            onClose={() => {
              this.store.opened = ''
            }}
            css={css`
              &.mantine-Modal-root .mantine-Modal-content {
                ${props.cssset};
              }
            `}
            centered
          >
            {props.children}
          </Modal>
        )
      },
      {
        displayName: '彈出窗口',
      },
    ),
  )
}
